<template>
  <div>
    <ManageForm8 />
  </div>
</template>
<script>
import ManageForm8 from "@/components/ManageForm8/ManageForm8";
export default {
  components: {
    ManageForm8,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>